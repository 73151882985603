import { useState, useEffect } from 'react';
import Image from 'next/image';
import { t } from 'react-switch-lang';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from './Link';
import Button from './Button';
import Cart from './Cart/Cart';
import ClientOnly from './ClientOnly';
import { useRouter } from './Router';
import { useCognito } from '../utils/Cognito';
import { logAmpEvent, events } from '../utils/Amplitude';
import { setScreenReaderAlert } from '../utils/Accessibility';
import style from '../styles/Navbar.module.scss';
import logo from '../public/assets/images/logos/NavLogo.png';
import { isDevelopment } from '../utils/HostingEnv';

function getCartQuantityState(items) {
  let cartEmpty = true;
  const cartQuantity = Object.values(items).reduce((totalQuantity, denomQuantities) => (
    totalQuantity + Object.values(denomQuantities)
      .reduce((productQuantity, quantity) => {
        if (quantity === '') cartEmpty = false;
        return productQuantity + parseInt(quantity || 0, 10);
      }, 0)
  ), 0);
  if (cartEmpty) cartEmpty = cartQuantity === 0;
  return [cartQuantity, cartEmpty];
}

export default function Navbar() {
  const [showAccountDropdown, toggleAccountDropdown] = useState(false);
  const [showHamburgerDropdown, toggleHamburgerDropdown] = useState(false);
  const [showCartDropdown, toggleCartDropdown] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(0);
  const [cartEmpty, setCartEmpty] = useState(true);
  const cartInfo = useSelector((state) => state.cart);
  const session = useSelector((state) => state.auth.session);
  const cognito = useCognito();
  const router = useRouter();
  const path = router.pathname;
  const hideDropdowns = () => {
    toggleHamburgerDropdown(false);
    toggleCartDropdown(false);
  };

  useEffect(() => {
    const [quantity, isEmpty] = getCartQuantityState(cartInfo.items);
    setCartQuantity(quantity);
    setCartEmpty(isEmpty);
  }, [cartInfo]);

  useEffect(() => {
    if (!showCartDropdown) return;
    if (cartEmpty) {
      setScreenReaderAlert(t('ReviewCart_Empty_Lbl'));
    } else if (cartInfo.locked > 0) {
      setScreenReaderAlert(t('ScreenReaderAlert_OpenedLockedCart'));
    } else {
      setScreenReaderAlert(t('ScreenReaderAlert_OpenedCart'));
    }
  }, [showCartDropdown, cartEmpty]); // eslint-disable-line react-hooks/exhaustive-deps

  // hide dropdowns when clicking away
  useEffect(() => {
    const clickAwayCallback = ({ target }) => {
      if (document.querySelector(`#${style.nav}`)?.contains?.(target)) return;
      hideDropdowns();
    };

    document.addEventListener('click', clickAwayCallback);

    return () => document.removeEventListener('click', clickAwayCallback);
  }, []);

  const getCartButtonLabel = (() => (cartQuantity === 1 ?
    t('ButtonDescription_CartDropdown_One') :
    t('ButtonDescription_CartDropdown_Many').replace('xItems', cartQuantity)));

  return (
    <nav id={style.nav} className={session ? style.session : undefined}>
      <div
        id="hamburger-dropdown"
        className={`${style.navContent} maxWidth18 ${showHamburgerDropdown ? style.expanded : ''}`}
      >
        <div className={style.row}>
          <button
            id={style.Hamburger}
            className={style.corners}
            type="button"
            onClick={() => {
              logAmpEvent(events.USER_CLICKED_NAVBAR_LINK, { Link: 'Hamburger Icon', Type: showHamburgerDropdown ? 'Hide' : 'Show' });
              toggleHamburgerDropdown(!showHamburgerDropdown);
              toggleCartDropdown(false);
            }}
            aria-label={t('ButtonDescription_HamburgerMenu')}
            title={t('ButtonDescription_HamburgerMenu')}
            data-action="disclosure"
            aria-controls="hamburger-dropdown"
            aria-expanded={showHamburgerDropdown ? 'true' : 'false'}
          >
            <FontAwesomeIcon icon={['fas', 'bars']} />
          </button>
          <div id={style.LogoCont}>
            <Link
              href="/"
              ampEvent={events.USER_CLICKED_NAVBAR_LINK}
              ampEventProps={{ Link: 'Logo' }}
              onClick={hideDropdowns}
            >
              <Image
                src={logo}
                alt={t('ImageDescription_Logo')}
                layout="fill"
                objectFit="contain"
                unoptimized
              />
            </Link>
          </div>
          <div id={style.Links}>
            {/* the extra div here is necessary for collapsible css to work properly */}
            <div>
              {isDevelopment && (
                <Link
                  href="/site-map"
                  ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                  ampEventProps={{ Link: 'Sitemap' }}
                  className={style.siteMapButton}
                  text={t('Navbar_Lbl_Sitemap')}
                  onClick={hideDropdowns}
                />
              )}
              <Link
                className={path === '/[lang]/about' ? style.currentPage : undefined}
                href="/about"
                ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                ampEventProps={{ Link: t('Navbar_Lbl_About', null, 'en') }}
                text={t('Navbar_Lbl_About')}
                onClick={hideDropdowns}
              />
              <Link
                className={path === '/' || path === '/[lang]' ? style.currentPage : undefined}
                href="/#products"
                ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                ampEventProps={{ Link: t('Navbar_Lbl_Products', null, 'en') }}
                text={t('Navbar_Lbl_Products')}
                onClick={hideDropdowns}
                onFocus={() => toggleAccountDropdown(false)}
              />
              <ClientOnly>
                {session ? (
                  <div className={style.dropdown} onMouseLeave={() => toggleAccountDropdown(false)}>
                    <button
                      type="button"
                      id={style.DropBtn}
                      // if clicked with a mouse, don't focus element by preventing default
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={(e) => {
                        // if element is focused, button was clicked by pressing the enter key
                        if (e.target === document.activeElement) {
                          toggleAccountDropdown(!showAccountDropdown);
                          logAmpEvent(events.USER_CLICKED_NAVBAR_LINK, { Link: t('Navbar_Lbl_Account', null, 'en') });
                        }
                      }}
                      onMouseEnter={() => toggleAccountDropdown(true)}
                      data-action="disclosure"
                      aria-controls="account-dropdown"
                      aria-expanded={showAccountDropdown ? 'true' : 'false'}
                    >
                      {`${t('Navbar_Lbl_Account')} `}
                      <FontAwesomeIcon icon={['far', 'chevron-down']} size="xs" />
                    </button>
                    <div
                      className={`${style.dropContent} ${showAccountDropdown ? style.show : ''}`}
                      onMouseLeave={() => toggleAccountDropdown(false)}
                      id="account-dropdown"
                    >
                      <Link
                        href="/settings"
                        ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                        ampEventProps={{ Link: t('Navbar_Lbl_Settings', null, 'en') }}
                        text={t('Navbar_Lbl_Settings')}
                        onClick={hideDropdowns}
                      />
                      <button
                        id={style.LogoutBtn}
                        type="button"
                        onClick={() => {
                          logAmpEvent(events.USER_CLICKED_NAVBAR_LINK, { Link: t('Navbar_Lbl_Logout', null, 'en') });
                          if (path === '/[lang]/checkout' || path === '/[lang]/verify-email' || path === '/[lang]/settings') {
                            router.push({ pathname: '/logout', query: { to: 'index' } });
                            return;
                          }
                          if (!cartEmpty && !window.confirm(t('Logout_Confirm'))) return; // eslint-disable-line no-alert
                          hideDropdowns();
                          cognito.signOut().then(() => {
                            logAmpEvent(events.USER_LOGGED_OUT);
                            setScreenReaderAlert(t('ScreenReaderAlert_LogoutSuccess'));
                          });
                        }}
                      >
                        {t('Navbar_Lbl_Logout')}
                      </button>
                    </div>
                  </div>
                ) : (
                  <Link
                    className={path === '/[lang]/login' ? style.currentPage : undefined}
                    href="/login"
                    ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                    ampEventProps={{ Link: t('Login_Title', null, 'en') }}
                    text={t('Login_Title')}
                    onClick={hideDropdowns}
                  />
                )}
              </ClientOnly>
            </div>
          </div>
          <div className={`${style.corners} ${style.cartCont}${showCartDropdown ? ' show' : ''}`}>
            <button
              type="button"
              id="cart-button"
              onClick={() => {
                logAmpEvent(events.USER_CLICKED_NAVBAR_LINK, { Link: 'Cart Icon', Type: showCartDropdown ? 'Hide' : 'Show' });
                toggleHamburgerDropdown(false);
                toggleCartDropdown(!showCartDropdown);
              }}
              onFocus={() => toggleAccountDropdown(false)}
              data-action="disclosure"
              aria-controls={style.Cart}
              aria-expanded={showCartDropdown ? 'true' : 'false'}
              aria-label={getCartButtonLabel()}
              title={getCartButtonLabel()}
            >
              <FontAwesomeIcon icon={['far', 'shopping-cart']} />
              {cartQuantity > 0 && (
                <span className={style.cartQuantity}>{cartQuantity > 99 ? 99 : cartQuantity}</span>
              )}
            </button>
            <div id={style.Cart}>
              <ClientOnly>
                {cartEmpty ? (
                  <p className={style.emptyCartText}>{t('ReviewCart_Empty_Lbl')}</p>
                ) : (
                  <>
                    <Cart />
                    {path === '/[lang]/checkout' || (
                      <Button
                        text={t('Cart_Checkout_Lbl')}
                        className={style.checkoutButton}
                        onClick={() => router.push('/checkout')}
                        ampEvent={events.USER_CLICKED_CART_CHECKOUT_BUTTON}
                      />
                    )}
                  </>
                )}
              </ClientOnly>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
