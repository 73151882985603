import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logAmpEvent } from '../utils/Amplitude';

/**
 * Custom `<button>`.
 *
 * Implements its own logic to indicate loading status during onClick handler execution.
 *
 * If both text and children are provided, only children will be rendered.
 *
 * Any extra props provided will be passed to `<button>`.
 * @param {object} props
 * @param {string} [props.text] text to be displayed for the button
 * @param {React.ReactNode} [props.children] ReactNode to be rendered inside `<button>`
 * @param {()=>Promise | Function} [props.onClick] either a function or an async function
 * @param {string} props.ampEvent Amplitude event to be logged when this button is clicked
 * @param {object} [props.ampEventProps] Amplitude event properties to be logged with the event
 * @param {string} [props.className] appended after the default class, "btn"
 * @param {"solid" | "outline"} [props.fill] appended style to define button type
 *   default: 'solid'
 * @param {string} [props.id]
 * @param {"button" | "submit" | "reset"} [props.type="button"] `type` attribute of `<button>`;
 *   default: 'button'
 * @param {boolean} [props.loading] indicates loading status;
 *   use if loading status depends on something other than onClick handler completion
 * @param {boolean} [props.disabled] `diabled` attribute of `<button>`;
 *   button will be automatically disabled while in loading state
 * @param {boolean} [props.successful] indicates button action was successful;
 *   if true, a checkmark icon should be rendered inside the `<button>`;
 * @param {string} [props.ariaLabel] `aria-label` attribute of `<button>`;
 * @param {string} [props.title] `title` attribute of `<button>`;
 */
export default function Button({
  text,
  children,
  onClick,
  className = '',
  fill = 'solid',
  ampEvent,
  ampEventProps = undefined,
  ariaLabel,
  title,
  disabled: disabledProp,
  loading: loadingProp,
  successful = false,
  ...props
}) {
  const [loading, setLoading] = useState(loadingProp);
  useEffect(() => setLoading(loadingProp), [loadingProp]);
  const disabled = disabledProp || loading || successful;
  return (
    <button
      type="button"
      {...props}
      aria-label={ariaLabel}
      title={title}
      disabled={disabled}
      className={`btn upperCase ${fill}${disabledProp ? ' disable' : ''} ${className}`}
      onClick={(e) => {
        if (disabled) return;
        if (ampEvent) logAmpEvent(ampEvent, ampEventProps);
        if (onClick) {
          setLoading(true);
          Promise.resolve(onClick(e)).finally(() => setLoading(false));
        }
      }}
    >
      <ButtonContent loading={loading} successful={successful}>
        {children || text}
      </ButtonContent>
    </button>
  );
}

function ButtonContent({ loading, successful, children }) {
  if (loading) {
    return (
      <FontAwesomeIcon
        icon={['fad', 'spinner-third']}
        spin
        className="fastSpin"
        size="1x"
      />
    );
  }
  if (successful) {
    return <FontAwesomeIcon icon={['fas', 'check']} size="1x" />;
  }
  return children;
}
