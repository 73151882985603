import NextLink from 'next/link';
import { getLanguage } from 'react-switch-lang';
import { logAmpEvent } from '../utils/Amplitude';

/**
 * Custom `<a>` tag that links to a relative path.
 *
 * If both text and children are provided, only children will be rendered.
 *
 * Any extra props provided will be passed to `<a>`.
 * @param {object} props
 * @param {string | import('url').UrlObject} props.href
 *   relative path to navigate to, or a URL object
 * @param {string} [props.text] text to be displayed for the link
 * @param {React.ReactNode} [props.children] ReactNode to be rendered inside `<a>`
 * @param {string} props.ampEvent Amplitude event to be logged when this link is clicked
 * @param {object} [props.ampEventProps] Amplitude event properties to be logged with the event
 * @param {string} [props.id]
 * @param {string} [props.className] appended after the default class, "link"
 * @param {Function} [props.onClick] callback to be executed on click
 */
export default function Link({
  href, text, children, ampEvent, ampEventProps, className = '', ...props
}) {
  let path;
  if (typeof href === 'string') {
    path = `/${getLanguage()}${href}`.replace(/\/*$/, '');
  } else {
    path = { ...href, pathname: `/${getLanguage()}${href.pathname}`.replace(/\/*$/, '') };
  }
  return (
    <NextLink href={path} passHref>
      <a
        {...props}
        href={href}
        className={`link ${className}`}
        onClick={
          (ampEvent || props.onClick) &&
          ((e) => {
            if (ampEvent) logAmpEvent(ampEvent, ampEventProps);
            props.onClick?.(e);
          })
        }
      >
        {children || text}
      </a>
    </NextLink>
  );
}

/**
 * Custom `<a>` tag that links to an external URL.
 *
 * If both text and children are provided, only children will be rendered.
 *
 * Any extra props provided will be passed to the `<a>`.
 * @param {object} props
 * @param {string} props.href external URL to navigate to
 * @param {string} [props.text] text to be displayed for the link
 * @param {React.ReactNode} [props.children] ReactNode to be rendered inside `<a>`
 * @param {string} props.ampEvent Amplitude event to be logged when this link is clicked
 * @param {object} [props.ampEventProps] Amplitude event properties to be logged with the event
 * @param {string} [props.id]
 * @param {string} [props.className] appended after the default class, "link"
 * @param {Function} [props.onClick] callback to be executed on click
 */
export function ExternalLink({
  href, text, children, ampEvent, ampEventProps, className = '', ...props
}) {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      {...props}
      href={href}
      className={`link ${className}`}
      onClick={
        (ampEvent || props.onClick) &&
        ((e) => {
          if (ampEvent) logAmpEvent(ampEvent, ampEventProps);
          props.onClick?.(e);
        })
      }
    >
      {children || text}
    </a>
  );
}
