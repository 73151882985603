import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { t } from 'react-switch-lang';
import Item from './Item';
import style from './Cart.module.scss';
import { formatCurrency, isKYCRequired, isPaysafe } from '../../utils/Helpers';
import { ORDER_FEE } from '../../utils/Constants';
import { removeFromCart } from '../../redux/actions/CartActions';
import { getObjectsWithCache } from '../../utils/AlgoliaHelpers';

/**
 * Returns the cart UI including the total (subtotal)
 * Look up the store state for the cart, search the prod code in alogolia to retreive
 * product info for each item; while you iterate, also add the price+fee to the total
 */
export default function Cart({
  setTotal,
  setKYCRequired,
  setCartHasPaysafe,
  setPaymentItems,
  reviewCart = false,
  location = 'Cart',
}) {
  const [subtotal, setSubtotal] = useState(0);
  const [listOfItems, setListOfItems] = useState([]);
  const cartInfo = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  async function getProductList() {
    const items = [];
    const paymentItems = [];
    const prodCodes = Object.keys(cartInfo.items);
    const products = await getObjectsWithCache(prodCodes);
    const sum = products.reduce((allProductTotal, product, i) => {
      if (!product) {
        dispatch(removeFromCart(prodCodes[i]));
        return allProductTotal;
      }
      return (
        allProductTotal + Object.entries(cartInfo.items[product.prodCode])
          .reduce((productTotal, [denom, quantity]) => {
            const denomFloat = parseFloat(denom);
            if (product.denominations.indexOf(denomFloat) < 0) {
              dispatch(removeFromCart(prodCodes[i], denom));
              return productTotal;
            }
            const fee = product.denominationsFee[product.denominations.indexOf(denomFloat)];
            items.push({
              prodCode: product.prodCode,
              name: product.name,
              kycType: product.kycType,
              quantity,
              denom,
              fee,
              img: product.imageURL,
            });
            paymentItems.push({
              ProdCode: product.prodCode,
              Amount: denomFloat,
              Quantity: quantity,
              Fee: fee,
            });
            return productTotal + (denomFloat + fee) * quantity;
          }, 0)
      );
    }, 0);
    if (setTotal) setTotal(sum + ORDER_FEE);
    if (setKYCRequired) {
      if (items.filter((e) => isKYCRequired(e.kycType)).length > 0) {
        setKYCRequired(true);
      } else {
        setKYCRequired(false);
      }
    }
    if (setCartHasPaysafe) {
      if (items.filter((e) => isPaysafe(e.prodCode)).length > 0) {
        setCartHasPaysafe(true);
      } else {
        setCartHasPaysafe(false);
      }
    }
    if (setPaymentItems) setPaymentItems(paymentItems);
    setSubtotal(sum);
    setListOfItems(items);
  }

  useEffect(() => getProductList(), [cartInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ul className={style.cartUnorderedList}>
        {listOfItems.map((item) => (<Item {...item} key={`${item.prodCode}-${item.denom}`} locked={cartInfo.locked > 0} reviewCart={reviewCart} location={location} />))}
      </ul>
      <div className={style.amountContainer}>
        <p className={`${style.amountLbl} upperCase`}>{t('ReviewCart_Subtotal_Lbl')}</p>
        <p className={style.amount}>{formatCurrency(subtotal)}</p>
      </div>
      { reviewCart || (
        <>
          <div className="amountContainer noBottom">
            <p className="amountLbl upperCase">{t('ReviewCart_OrderFee_Lbl')}</p>
            <p className="amount">{formatCurrency(ORDER_FEE)}</p>
          </div>
          <div className="amountContainer">
            <p className="amountLbl upperCase">{t('ReviewCart_Total_Lbl')}</p>
            <p className="amount">{formatCurrency(subtotal + ORDER_FEE)}</p>
          </div>
        </>
      )}
    </>
  );
}
