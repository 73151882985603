import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage, t } from 'react-switch-lang';
import { useDispatch } from 'react-redux';
import { ExternalLink } from '../Link';
import { Quantity } from '../Field';
import { logAmpEvent, events } from '../../utils/Amplitude';
import { formatCurrency, formatProductDenom, isKYCRequired, isPaysafe } from '../../utils/Helpers';
import { changeQuantity, removeFromCart } from '../../redux/actions/CartActions';
import style from './Item.module.scss';
import { replaceProductString, setScreenReaderAlert } from '../../utils/Accessibility';

export default function Item({
  prodCode,
  img,
  denom,
  name,
  fee,
  quantity,
  kycType,
  locked,
  reviewCart,
  location,
}) {
  const denomText = formatProductDenom(denom);
  const isKyc = isKYCRequired(kycType);
  const dispatch = useDispatch();
  const lang = getLanguage();

  return (
    <li>
      <div className={style.item}>
        <Image src={img[lang]} width={80} height={50} unoptimized alt={name[lang]} />
        <div className={style.description}>
          <span className={style.title}>{`${denomText} ${name[lang]}`}</span>
          {fee > 0 && <span className={style.subtitle}>{`${denomText} + ${formatCurrency(fee)} ${t('Cart_Fee_Lbl')}`}</span>}
          <Quantity
            value={quantity}
            id={`${prodCode}-${denom}${reviewCart ? '-review' : ''}`}
            locked={locked}
            disabled={isPaysafe(prodCode)}
            setValue={(v, onChange) => dispatch(changeQuantity(prodCode, denom, v, onChange))}
            cart
            compact
            productName={name[lang]}
            denom={denom}
            prodCode={prodCode}
            location={location}
          />
        </div>
        {locked || (
        <button
          type="button"
          className={style.remove}
          onClick={() => {
            logAmpEvent(events.USER_CLICKED_CART_REMOVE_ITEM, {
              Product: prodCode,
              Denomination: denom,
            });
            setScreenReaderAlert(replaceProductString(t('ScreenReaderAlert_RemovedFromCart'), denom, name[lang]));
            dispatch(removeFromCart(prodCode, denom));
          }}
          aria-label={replaceProductString(t('ButtonDescription_RemoveFromCart'), denom, name[lang])}
          title={t('ButtonDescription_RemoveFromCartTooltip')}
        >
          <FontAwesomeIcon icon={['fas', 'times']} />
        </button>
        )}

      </div>
      {isKyc && (
        <div className={style.alert}>
          {t('Cart_KYCWarning')}
          <ExternalLink
            href={t('Link_GamePlayShop_RegistrationProcessHelp')}
            text={t('Cart_KYCWarningHelp')}
            aria-label={t('LinkDescription_WhatDoesThisMean')}
            ampEvent={events.USER_CLICKED_HELP_ARTICLE_DIRECT_LINK}
            ampEventProps={{ 'Prompt Text': t('Cart_KYCWarningHelp'), Article: t('Link_GamePlayShop_RegistrationProcessHelp') }}
          />
        </div>
      )}
    </li>
  );
}
