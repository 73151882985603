import Head from 'next/head';
import { t } from 'react-switch-lang';
import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { setPageTitle } from '../utils/Accessibility';
import SkipToContent from './SkipToContent';

/**
 * Layout wrapper for all pages.
 *
 * Renders NavBar and Footer, alongside any children.
 *
 * Handles accessibility such as page titles and renders skip to links.
 *
 * @param {object} props
 * @param {string} props.title accessible page title passed into `<Head>`
 * @param {string} [props.description] description meta tag passed into `<Head>`
 * @param {React.ReactNode} [props.children] ReactNode to be rendered inside wrapper
 * @param {string} [props.className] appended after the default class, "content"
 * @param {boolean} [props.paymentScreen] indicates that current screen is the payment screen
 */
export default function MainLayout({
  title,
  description,
  children,
  className = '',
  paymentScreen = false,
}) {
  // Page title for accesibility
  if (title) setPageTitle(title);

  return (
    <>
      <Head>
        {title && <title>{`${title} | ${t('OfficialSiteName')}`}</title>}
        {description && <meta name="description" content={description} />}
        <link rel="icon" href="/Favicon.png" />
      </Head>
      <div id="main-cont">
        <SkipToContent paymentScreen={paymentScreen} />
        <Navbar />
        <div id="main" className={`content ${className}`}>
          {children}
        </div>
        <hr />
        <Footer />
      </div>
    </>
  );
}
