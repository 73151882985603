import React from 'react';
import { translate, t } from 'react-switch-lang';
import { moveFocusTo } from '../utils/Accessibility';

function SkipToContent({ paymentScreen }) {
  return (
    <div id="SkipTo">
      {paymentScreen ? (
        <>
          <SkipToButton skipTo="payment-form" textKey="ButtonDescription_SkipToPayment" />
          <SkipToButton skipTo="review-cart" textKey="ButtonDescription_SkipToReviewCart" id="review-cart-skip-link" />
        </>
      ) : (
        <SkipToButton skipTo="main" textKey="ButtonDescription_SkipToContent" />
      )}
      <SkipToButton skipTo="cart-button" textKey="ButtonDescription_SkipToCart" />
      <SkipToButton skipTo="footer" textKey="ButtonDescription_SkipToFooter" />
    </div>
  );
}

function SkipToButton({ skipTo, textKey, ...args }) {
  return <button type="button" onClick={() => moveFocusTo(skipTo)} {...args}>{t(textKey)}</button>;
}

export default translate(SkipToContent);
