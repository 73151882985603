export const formatZipCode = (value) => (!value ?
  '' :
  value.replace(/[^\d-]/g, '')
    .replace(/^(\d{5})(\d{1})$/, '$1-$2')
    .replace(/^(\d{5})(\d{4})$/, '$1-$2'));

export const formatDateOfBirth = (value) => (!value ?
  '' :
  value.replace(/[^\d-]/g, '')
    .replace(/^(.{10})(.*)$/g, '$1')
    .replace(/^(\d{4})(\d{1})$/, '$1-$2')
    .replace(/^(\d{4})-(\d{2})(\d{1})$/, '$1-$2-$3')
    .replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3'));

export const formatPhoneNumber = (value) => (!value ?
  '' :
  value.replace(/[^\d-]/g, '')
    .replace(/^(.{12})(.*)$/g, '$1')
    .replace(/^(\d{3})(\d{1})$/, '$1-$2')
    .replace(/^(\d{3})-(\d{3})(\d{1})$/, '$1-$2-$3')
    .replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3'));
export const formatAmountEn = (value) => {
  if (!value) return '';
  if (/^\.$/.test(value)) return '0.';
  // remove all dollar signs, but leave one at the front if present
  return `${/^\$/.test(value) ? '$' : ''}${value.replaceAll('$', '')}`.replace(/[^0-9,.$]/g, '');
};
/**
 * Replaces all iOS single quotes in the given string with regular single quotes
 * @param {string} value
 * @returns {string}
 */
export const replaceSingleQuotes = (value) => (value ? value.replace(/[‘’]/g, "'") : '');
