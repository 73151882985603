import algoliasearch from 'algoliasearch';
import { isKYCRequired } from './Helpers';

export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
);
const index = searchClient.initIndex(process.env.NEXT_PUBLIC_ALGOLIA_INDEX);

/** @type {Object.<string, import('../components/Algolia/Hits').Hit>} */
const cachedAlgoliaProducts = {};

/**
 * Stores hits in memory
 * @param {import('../components/Algolia/Hits').Hit[]} hits
 */
export function cacheHits(hits) {
  if (!(hits.length > 0)) return;
  hits.forEach((hit) => {
    cachedAlgoliaProducts[hit.prodCode] = hit;
  });
}

/**
 * @param {string[]} prodCodes array of `prodCode`s
 * @param {bool} [ignoreCache]
 *  if true, ignores cached products and overwrites them with the latest from algolia
 * @returns {(import('../components/Algolia/Hits').Hit|undefined)[]} if the `prodCodes` contains
 * a prodCode that doesn't exist in algolia, that index will be undefined
 */
export async function getObjectsWithCache(prodCodes, ignoreCache = false) {
  const cachedProdCodes = ignoreCache ? [] : Object.keys(cachedAlgoliaProducts);
  const newProdCodes = prodCodes.filter((prodCode) => !cachedProdCodes.includes(prodCode));
  if (newProdCodes.length > 0) {
    const products = (await index.getObjects(newProdCodes)).results;
    products.forEach((product) => {
      if (!product) return; // prodCode not found
      cachedAlgoliaProducts[product.prodCode] = product;
    });
  }
  return prodCodes.map((prodCode) => cachedAlgoliaProducts[prodCode]);
}

/**
 * @param {string} prodCode
 * @param {bool} [ignoreCache]
 *  if true, ignores cached products and overwrites them with the latest from algolia
 * @throws {{name:string,status:number,message:string}}
 *  if prodCode doesn't exist in algolia, throws error with status: 404
 */
export async function getObjectWithCache(prodCode, ignoreCache = false) {
  if (ignoreCache || !cachedAlgoliaProducts[prodCode]) {
    cachedAlgoliaProducts[prodCode] = await index.getObject(prodCode);
  }
  return cachedAlgoliaProducts[prodCode];
}

export async function getKYCType(cartInfo) {
  const products = await getObjectsWithCache(Object.keys(cartInfo.items), true);

  const kycRequiredProducts = products.reduce((acc, product) => {
    if (isKYCRequired(product.kycType)) {
      return acc + 1;
    }
    return acc;
  }, 0);
  return kycRequiredProducts > 0;
}
