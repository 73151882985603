import { t } from 'react-switch-lang';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { endOfToday, subYears } from 'date-fns';
import { isDevelopment } from './HostingEnv';

/**
 * Combines multiple validator functions into a single validator function that
 * runs each validator until a failed validation is found
 * @param {function[]} validators
 * array of validators that takes in the value and returns an error message or undefined
 * @param {boolean} [isRequired] indicates if this a required or optional field; defaults to true
 * @param {string} [customMsg] optional custom message to return if validation fails
 * @returns {(value:string)=>string|undefined}
 * a function that takes in a value to validate and returns the result of validation;
 * the result of validation is either:
 * - an error message string if validation fails
 * - or undefined if validation passes
 */
function combineValidators(validators, isRequired = true, customMsg = null) {
  return (value) => {
    if (!value) return isRequired ? t('Validation_Required') : undefined;

    let result;
    // run validators until we find one that returns a truthy value
    validators.some((validator) => {
      result = validator(value); // result set to error message if validate fails, else undefined
      return result; // returning any truthy value will stop validation
    });
    return result ? ((customMsg && t(customMsg)) || result) : undefined;
  };
}

export const THIRTEEN_YEARS_AGO = subYears(endOfToday(), 13);
function getMaxDateOfBirth(state) {
  switch (state) {
    default:
      return THIRTEEN_YEARS_AGO;
  }
}

const validDate = () => (value) => {
  if (!moment(value).isValid()) return t('Validation_Invalid_DateOfBirth');
  if (moment(value).isBefore(moment('1899-12-31'))) return t('Validation_Invalid_DateOfBirth');
  return undefined;
};

/* ******************************* Validator Generator Functions ******************************* */
const ageOfMajority = (state) => (value) => {
  if (new Date(`${value}T00:00`) <= getMaxDateOfBirth(state)) return undefined;
  return t('Validation_Invalid_AgeOfMajority'); // invalid date also returns error msg
};
const maxLength = (length) => (value) => (value.length > length ? t('Validation_Length_Max').replace('xMax', length) : undefined);
const minLength = (length) => (value) => (value.length < length ? t('Validation_Length_Min').replace('xMin', length) : undefined);
const lengthRange = (min, max) => (value) => minLength(min)(value) || maxLength(max)(value);
const exactLength = (length) => (value) => (value.length !== length ? t('Validation_Length_Exact').replace('xLength', length) : undefined);
/**
 * Generates a regex validator
 * @param {RegExp} regex regular expression to test against
 * @param {string} failMsg lang key for the text to return if the validation fails
 * @returns {(value:string)=>string|undefined} a validator function
 */
const regexValidator = (regex, failMsg) => (value) => (!regex.test(value) ? t(failMsg) : undefined);

/* **************************************** Validators **************************************** */
const passwordRegex = (value) => {
  const numberRegex = /.*[0-9].*/;
  const upperCaseRegex = /.*[A-Z].*/;
  const specialCharRegex = /.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~].*/;

  if (!specialCharRegex.test(value)) {
    return t('Registration_Validation_Password_SpecialChar');
  }
  if (!upperCaseRegex.test(value)) {
    return t('Registration_Validation_Password_UpperChar');
  }
  if (!numberRegex.test(value)) {
    return t('Registration_Validation_Password_Number');
  }
  return undefined;
};
const validCharRegex = regexValidator(/^[&A-Za-z0-9-.,'‘’\s]*$/, 'Validation_ValidChar_General');
const cardholderNameRegex = regexValidator(/^[A-Za-z-.'‘’\s]*$/, 'Validation_Invalid_CardholderName');
const emailRegex = regexValidator(/^[\w!#$%&*+/=?`{|}~^-]+(?:\.[\w!#$%&*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,32}$/, 'Validation_Invalid_Email');
const zipCodeRegex = regexValidator(/^\d{5}(?:[-\s]\d{4})?$/, 'Validation_Invalid_PostalCode');
const phoneNumberRegex = regexValidator(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Validation_Invalid_PhoneNumber');
const phoneNumberUS = (value) => (parsePhoneNumber(value, 'US').country !== 'US' ? t('Validation_NotAmerican_PhoneNumber') : undefined);
const dateOfBirthRegex = regexValidator(/[0-9]{4}-[0-9]{2}-[0-9]{2}/, 'Validation_Invalid_DateOfBirth');

export const validateCheckbox = (value) => (value ? undefined : true);
export const validateRequired = (value) => (value ? undefined : t('Validation_Required'));
export const validateDateOfBirth = (value, state = '') => (
  combineValidators([dateOfBirthRegex, validDate(), ageOfMajority(state)])(value)
);
export const validateEmail = combineValidators([maxLength(50), emailRegex]);
export const validatePassword = combineValidators([lengthRange(10, 20), passwordRegex]);
export const validateVerificationCode = combineValidators([exactLength(6)], true, 'Validation_Invalid_VerificationCode');
export const validateCallVerificationCode = combineValidators([exactLength(4)], true, 'Validation_Invalid_VerificationCode');
export const validateName = combineValidators([maxLength(30), validCharRegex]);
export const validateFullName = combineValidators([lengthRange(2, 45), cardholderNameRegex]);
export const validateStreet = combineValidators([maxLength(60), validCharRegex]);
export const validateSuite = combineValidators([maxLength(20), validCharRegex], false);
export const validateCity = combineValidators([maxLength(60), validCharRegex]);
export const validateFileSize = combineValidators([(size) => (size < 5.9 ? undefined : t('Validation_PhotoId_OverLimit'))]); // ensure base64 encoded file size is less than 5.9MB (lambda invocation payload limit)
export const validateZipCode = combineValidators([zipCodeRegex]);
export const validatePhoneNumber = combineValidators([phoneNumberRegex]);
export const validatePhoneNumberUS = isDevelopment ? // disable US phone validation on testserver
  validatePhoneNumber : combineValidators([phoneNumberRegex, phoneNumberUS]);
