import { useState, useEffect } from 'react';

/**
 * If you already have a defined component that should be rendered on the client-side,
 * you can use this custom hook to render null based on the value of hasMounted
 * instead of wrapping the entire returning jsx with the `ClientOnly` component defined below
 */
export function useHasMounted() {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => setHasMounted(true), []);
  return hasMounted;
}

/**
 * Wrapper component for implementing two-pass rendering
 * of the children to prevent hydration mismatch.
 * This basically lets the chilren not to be part of the exported html during build,
 * but re-renders the component immediately after the rehydration.
 *
 * Reference: https://github.com/vercel/next.js/issues/17463#issuecomment-701472340
 *
 * @param {object} props
 * @param {React.ReactNode} [props.children] ReactNode to be client side rendered
 */
export default function ClientOnly({ children }) {
  const hasMounted = useHasMounted();
  return hasMounted ? children : null;
}
