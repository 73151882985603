import Image from 'next/image';
import { t } from 'react-switch-lang';

import Link, { ExternalLink } from './Link';
import { events } from '../utils/Amplitude';
import logo from '../public/assets/images/logos/FooterLogo.png';
import usFlag from '../public/assets/svgs/UnitedStatesFlag.svg';
import badge from '../public/assets/images/logos/PaysafecardBadge.png';
import visaLogo from '../public/assets/images/logos/VisaLogo.png';
import mcLogo from '../public/assets/images/logos/MastercardLogo.png';

function FooterLink({ langKey, href }) {
  return (
    <Link
      href={href}
      ampEvent={events.USER_CLICKED_FOOTER_LINK}
      ampEventProps={{ Link: t(langKey, null, 'en') }}
      text={t(langKey)}
    />
  );
}

export default function Footer() {
  return (
    <footer id="footer">
      <div className="maxWidth18">
        <div className="column">
          <Image
            className="logo"
            alt={t('ImageDescription_Logo')}
            src={logo}
            layout="fixed"
            width={100}
            height={100}
            unoptimized
          />
          <p>{t('Footer_Lbl_Header')}</p>
          <span id="disclaimer">{t('Footer_Lbl_Disclaimer')}</span>
        </div>
        <div id="badge">
          <Image
            src={badge}
            alt={t('ImageDescription_PaysafecardBadgeAlt')}
            title={t('ImageDescription_PaysafecardBadgeTitle')}
            layout="fixed"
            width={120}
            height={135}
            unoptimized
          />
        </div>
        <div className="column">
          <h3>{t('Navbar_Lbl_Products')}</h3>
          <FooterLink langKey="Landing_Filter_PaymentVoucher" href={{ pathname: '/', hash: '#products', query: { filter: 'Payment Voucher' } }} />
          <FooterLink langKey="Landing_Filter_GamingCard" href={{ pathname: '/', hash: '#products', query: { filter: 'Gaming Card' } }} />
          <FooterLink langKey="Landing_Filter_GiftCard" href={{ pathname: '/', hash: '#products', query: { filter: 'Gift Card' } }} />
          <FooterLink langKey="Footer_Lbl_ViewAll" href={{ pathname: '/', hash: '#products' }} />
        </div>
        <div className="column">
          <h3>{t('Footer_Lbl_Support')}</h3>
          <FooterLink href="/about" langKey="Footer_Lbl_AboutUs" />
          <ExternalLink
            text={t('Footer_Lbl_HelpCentre')}
            href={t('Link_GamePlayShop_Help')}
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: t('', null, 'en') }}
          />
        </div>
        <div className="column">
          <h3>{t('Footer_Lbl_Legal')}</h3>
          <FooterLink langKey="Footer_Lbl_TermsOfUse" href="/terms-of-use" />
          <FooterLink langKey="Footer_Lbl_RefundPolicy" href={{ pathname: '/terms-of-use', hash: '#refund-policy' }} />
          <FooterLink langKey="Footer_Lbl_PrivacyPolicy" href="/privacy-policy" />
          <FooterLink langKey="Footer_Lbl_Accessibility" href="/accessibility-statement" />
        </div>
        <div id="bottom">
          <div className="footer-card-container">
            <Image
              src={usFlag}
              alt={t('ImageDescription_USAFlag')}
              layout="fixed"
              width={24}
              height={18}
              unoptimized
            />
            <span className="label">{t('Footer_Lbl_USDollars')}</span>
          </div>
          <div className="footer-card-container">
            <Image
              src={visaLogo}
              alt={t('ImageDescription_Visa')}
              layout="fixed"
              width={48}
              height={18}
              unoptimized
            />
            <Image
              src={mcLogo}
              alt={t('ImageDescription_Mastercard')}
              layout="fixed"
              width={30}
              height={18}
              unoptimized
            />
            <span className="label">{t('Footer_Lbl_CreditDebit')}</span>
          </div>
        </div>
      </div>
    </footer>
  );
}
