import { Field as ReactField } from 'react-final-form';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage, t, translate } from 'react-switch-lang';
import DatePicker from 'react-datepicker';
import { validateDateOfBirth } from '../utils/Validation';
import { formatDateOfBirth } from '../utils/Format';
import 'react-datepicker/dist/react-datepicker.css';
import Button from './Button';
import { giftCartAlert, paysafeCartAlert } from '../utils/Helpers';
import { MAX_DENOM_QUANTITY, PHONE_VERIFY_METHOD } from '../utils/Constants';
import { replaceProductString, setScreenReaderAlert } from '../utils/Accessibility';
import { events, logAmpEvent } from '../utils/Amplitude';

export function parseStringToDate(dateStr) {
  if (validateDateOfBirth(dateStr)) return null;

  const d = new Date();
  const ar = dateStr.split('-');
  d.setFullYear(parseInt(ar[0], 10), parseInt(ar[1], 10) - 1, parseInt(ar[2], 10));
  return d;
}

export function parseDateToString(date) {
  if (!(date instanceof Date)) return '';
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  const year = date.getFullYear();

  return [year, month, day].join('-');
}

export function Label({ text, id, name, optional, formatText, labelWithMargin }) {
  return (
    <label htmlFor={id} id={`${name}Lbl`}>
      <p className={labelWithMargin ? 'labelWithMargin' : undefined}>
        {text}
        {optional && <span className="optionalLbl">{`(${t('Form_Lbl_Optional')})`}</span>}
      </p>

      {formatText && <p className="formatLbl">{formatText}</p>}
    </label>
  );
}

export function Default({
  id,
  name,
  label,
  placeholder,
  helpText,
  formatText,
  initialValue = '',
  format = undefined,
  parse = format,
  autoComplete = '',
  className = '',
  optional = false,
  sensitive = true,
  disabled = false,
  disableFieldStateUI = false,
  labelWithMargin = false,
  onChange = undefined,
}) {
  return (
    <ReactField
      name={name}
      initialValue={initialValue}
      formatOnBlur={!!format}
      format={format}
      parse={parse}
    >
      {({ input, meta }) => (
        <div className={`${className} formField`}>
          <Label
            text={label}
            name={name}
            optional={optional}
            id={id}
            formatText={formatText}
            labelWithMargin={labelWithMargin}
          />
          <div>
            <input
              disabled={disabled}
              {...input}
              id={id}
              name={name}
              placeholder={placeholder}
              autoComplete={autoComplete}
              className={`${sensitive ? 'inspectletIgnore' : ''} form-control ${((!disabled && !disableFieldStateUI && meta.touched) || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')}`}
              onChange={(e) => {
                onChange?.(e);
                input.onChange(e);
              }}
            />
          </div>

          {(meta.error || meta.submitError) && meta.touched && (
          <span id={`${id}Error`} className="errorTxt invalid-feedback">{meta.error || meta.submitError}</span>
          )}

          {helpText && <p className="helpTxt">{helpText}</p>}
        </div>
      )}
    </ReactField>
  );
}

export function TextArea({
  id,
  name,
  label,
  placeholder,
  helpText,
  formatText,
  initialValue = '',
  format = undefined,
  parse = format,
  autoComplete = '',
  className = '',
  optional = false,
  sensitive = true,
  disabled = false,
  disableFieldStateUI = false,
  labelWithMargin = false,
  onChange = undefined,
  rows = undefined,
}) {
  return (
    <ReactField
      name={name}
      initialValue={initialValue}
      formatOnBlur={!!format}
      format={format}
      parse={parse}
    >
      {({ input, meta }) => (
        <div className={`${className} formField`}>
          <Label
            text={label}
            name={name}
            optional={optional}
            id={id}
            formatText={formatText}
            labelWithMargin={labelWithMargin}
          />
          <div>
            <textarea
              disabled={disabled}
              {...input}
              id={id}
              name={name}
              placeholder={placeholder}
              autoComplete={autoComplete}
              className={`${sensitive ? 'inspectletIgnore' : ''} form-control ${((!disabled && !disableFieldStateUI && meta.touched) || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')}`}
              onChange={(e) => {
                onChange?.(e);
                input.onChange(e);
              }}
              rows={rows}
            />
          </div>

          {(meta.error || meta.submitError) && meta.touched && (
          <span id={`${id}Error`} className="errorTxt invalid-feedback">{meta.error || meta.submitError}</span>
          )}

          {helpText && <p className="helpTxt">{helpText}</p>}
        </div>
      )}
    </ReactField>
  );
}

export function File({
  id,
  name,
  label,
  autoComplete = '',
  className = '',
}) {
  function fileInputOnClick() {
    logAmpEvent(events.USER_CLICKED_BROWSE_FOR_FILE_INPUT);
    document.getElementById('FileInput').click();
  }
  return (
    <ReactField
      name={name}
    >
      {({ input: { value, onChange, ...input }, meta }) => (
        <div className={`${className} formField fileField`}>
          <Label text={label} id={`${id}File`} name={name} />
          <div>
            <input
              {...input}
              id={id}
              type="file"
              accept="image/png, image/jpeg, image/gif, image/jpg"
              hidden="hidden"
              onChange={({ target }) => {
                if (Object.keys(target.files).length === 0) {
                  onChange(null);
                  return;
                }
                onChange(target.files);
              }}
              className="inspectletIgnore"
              autoComplete={autoComplete}
            />
            <div className="input-group fileContainer">
              <input
                type="text"
                readOnly
                id={`${id}File`}
                name={name}
                onClick={() => fileInputOnClick()}
                onKeyPress={(e) => { e.preventDefault(); fileInputOnClick(); }}
                className={`inspectletIgnore form-control fileInput ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')}`}
                value={value ? value[0].name : 'No File'}
                onChange={onChange}
              />
              <Button
                className="fileButton"
                text={t('Browse_Btn')}
                onClick={() => document.getElementById('FileInput').click()}
                ariaLabel={t('ButtonDescription_BrowseFile')}
                ampEvent={events.USER_CLICKED_BROWSE_FOR_FILE_BUTTON}
              />
            </div>
          </div>

          {(meta.error || meta.submitError) && meta.touched && (
          <span id={`${id}Error`} className="errorTxt invalid-feedback">{meta.error || meta.submitError}</span>
          )}
        </div>
      )}
    </ReactField>
  );
}

export function Phone({
  id,
  name,
  label,
  placeholder,
  helpText,
  formatText,
  initialValue = '',
  format = undefined,
  parse = format,
  autoComplete = '',
  className = '',
  optional = false,
  onClick,
  loading,
  sentCode,
  verifiedPhone,
  isPhoneCall = false,
  disableResend,
}) {
  function getButtonText() {
    if (disableResend && sentCode) {
      return isPhoneCall ? t('KYC_TryAgain_Lbl') : t('KYC_ResendText_Lbl');
    }
    return isPhoneCall ? t('KYC_CallPhone_Btn') : t('KYC_SendCode_Btn');
  }

  return (
    <ReactField
      name={name}
      initialValue={initialValue}
      formatOnBlur={!!format}
      format={format}
      parse={parse}
    >
      {({ input, meta }) => (
        <div className={`${className} formField phoneField`}>
          <Label text={label} name={name} optional={optional} id={id} formatText={formatText} />
          <div className="phoneContainer">
            <div className="input-group phoneInput">
              <input
                disabled={verifiedPhone}
                {...input}
                id={id}
                name={name}
                placeholder={placeholder}
                autoComplete={autoComplete}
                className={`inspectletIgnore form-control ${verifiedPhone ? 'is-valid' : ''} ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')}`}
              />
            </div>
            {!verifiedPhone && (
            <Button
              className={`phoneButton ${disableResend ? 'disabled' : ''}`}
              loading={loading}
              onClick={onClick}
              ampEvent={disableResend ?
                events.USER_CLICKED_SEND_PHONE_PIN_BUTTON :
                events.USER_CLICKED_RESEND_PHONE_PIN_BUTTON}
              ampEventProps={{
                Method: isPhoneCall ?
                  PHONE_VERIFY_METHOD.VOICE :
                  PHONE_VERIFY_METHOD.TEXT,
              }}
            >
              {getButtonText()}
            </Button>
            )}
          </div>
          {(meta.error || meta.submitError) && meta.touched && (
            <span id={`${id}Error`} className="errorTxt invalid-feedback">{meta.error || meta.submitError}</span>
          )}

          {helpText && <p className="helpTxt">{helpText}</p>}
        </div>
      )}
    </ReactField>
  );
}

export function PhonePin({
  id,
  name,
  label,
  placeholder,
  helpText,
  formatText,
  initialValue = '',
  format = undefined,
  parse = format,
  autoComplete = '',
  className = '',
  optional = false,
  disabled = false,
  isPhoneCall = false,
  onClick,
  loading,
}) {
  return (
    <ReactField
      name={name}
      initialValue={initialValue}
      formatOnBlur={!!format}
      format={format}
      parse={parse}
    >
      {({ input, meta }) => (
        <div className={`${className} formField phonePinField`}>
          <Label text={label} name={name} optional={optional} id={id} formatText={formatText} />
          <div className="phoneContainer">
            <div className="input-group phoneInput">
              <input
                disabled={disabled}
                {...input}
                id={id}
                name={name}
                placeholder={placeholder}
                autoComplete={autoComplete}
                className={`inspectletIgnore form-control ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')}`}
              />
            </div>
            <Button
              className="phonePinButton"
              text={t('Verify_Btn')}
              loading={loading}
              onClick={onClick}
              ampEvent={events.USER_CLICKED_VERIFY_PHONE_PIN_BUTTON}
              ampEventProps={{
                Link: isPhoneCall ?
                  PHONE_VERIFY_METHOD.VOICE :
                  PHONE_VERIFY_METHOD.TEXT,
              }}
            />
          </div>
          {(meta.error || meta.submitError) && meta.touched && (
            <span id={`${id}Error`} className="errorTxt invalid-feedback">{meta.error || meta.submitError}</span>
          )}

          {helpText && <p className="helpTxt">{helpText}</p>}
        </div>
      )}
    </ReactField>
  );
}

export function Checkbox({
  id,
  name,
  label,
  initialValue,
  className = '',
  showAlert = false,
  errorMsg = t('Required_Lbl'),
}) {
  return (
    <ReactField name={name} initialValue={initialValue} type="checkbox">
      {({ input, meta }) => (

        <div className={`${className} formCheckbox form-check`}>
          <input
            {...input}
            className={`form-check-input ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : '')}`}
            type="checkbox"
            id={id}
            name={name}
          />
          <div className="chkLabelContainer">
            <label htmlFor={id} className="form-check-label">
              {showAlert && (meta.error || meta.submitError) && meta.touched && (
                <span id={`${id}Error`} className="errorTxt invalid-feedback upperCase">
                  {`(${errorMsg})`}
                  {' '}
                </span>
              )}
              {label}
            </label>
          </div>

        </div>

      )}
    </ReactField>
  );
}

function PasswordField({
  id,
  name,
  label,
  placeholder,
  formatText,
  className = '',
  showRules = false,
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <ReactField name={name}>
      {({ input, meta }) => (
        <div className={`${className} formField password`}>
          <div className="passwordLblDiv">
            <Label text={label} id={id} name={name} />
          </div>
          {formatText && <p className="formatLbl">{formatText}</p>}
          <div className="password-field-container">
            <div>
              <input
                {...input}
                type={showPassword ? 'text' : 'password'}
                id={id}
                name={name}
                placeholder={placeholder}
                className={`inspectletIgnore form-control password-field ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')}`}
                autoComplete={showRules ? 'new-password' : 'current-password'}
              />
            </div>

            <button
              type="button"
              title={t(showPassword ? 'LinkDescription_HidePassword' : 'LinkDescription_ShowPassword')}
              className="passwordShowToggle"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <FontAwesomeIcon icon={['fal', showPassword ? 'eye-slash' : 'eye']} fixedWidth />
            </button>
          </div>

          {(meta.error || meta.submitError) && meta.touched && (
            <span id="passwordError" className="errorTxt invalid-feedback">
              {meta.error || meta.submitError}
            </span>
          )}

          {showRules && (
            <>
              <p className="helpTxt">{t('Auth_Field_Password_Help')}</p>
              <ul className="helpTxt">
                <li>{t('Auth_Field_Password_Help_Length')}</li>
                <li>{t('Auth_Field_Password_Help_SpecialChar')}</li>
                <li>{t('Auth_Field_Password_Help_UppercaseChar')}</li>
                <li>{t('Auth_Field_Password_Help_Number')}</li>
              </ul>
            </>
          )}
        </div>
      )}
    </ReactField>
  );
}
export const Password = translate(PasswordField);

export function DateField({
  id,
  name,
  label,
  placeholder = t('KYC_Field_DoB_Format'),
  helpText,
  formatText = t('KYC_Field_DoB_Format'),
  initialValue = '',
  maxDate,
  format = formatDateOfBirth,
  parse = format,
  className = '',
  optional = false,
  setValue,
}) {
  const [isOpen, setOpen] = useState(false);
  const [stateOnMouseDown, setStateOnMouseDown] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    parseStringToDate(initialValue) || maxDate || new Date()
  );

  useEffect(() => {
    if (isOpen) {
      const el = document.querySelector('.react-datepicker');
      el.setAttribute('tabIndex', -1);
      el.focus();
    }
  }, [isOpen]);

  return (
    <ReactField
      name={name}
      initialValue={initialValue}
      formatOnBlur={!!format}
      format={format}
      parse={parse}
    >
      {({ input, meta }) => (
        <div className={`${className} formField`}>
          <Label text={label} name={name} optional={optional} id={id} formatText={formatText} />
          <div className="input-group inspectlet-sensitive">
            <input
              {...input}
              onChange={(e) => {
                const d = parseStringToDate(e.target.value);
                if (d) setSelectedDate(d);
                input.onChange(e);
              }}
              id={id}
              name={name}
              placeholder={placeholder}
              className={`inspectletIgnore form-control ${(meta.touched || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')}`}
            />
            <DatePicker
              onChange={(d) => {
                setSelectedDate(d);
                setValue(name, parseDateToString(d));
              }}
              autoComplete="off"
              dateFormat="yyyy-MM-dd"
              showMonthDropdown
              showYearDropdown
              preventOpenOnFocus
              autoFocus={false}
              autoCorrect={false}
              dropdownMode="select"
              placeholderText={placeholder}
              selected={selectedDate}
              open={isOpen}
              onClickOutside={() => setOpen(false)}
              onSelect={() => setOpen(false)}
              className="form-control"
              tabIndex="-1"
              disabled
              locale={getLanguage()}
              maxDate={maxDate}
            />
            <Button
              className="datepickerBtn"
              type="button"
              title={t('ButtonDescription_Datepicker')}
              // needed to cancel out the effects of onClickOutside
              onMouseDown={() => setStateOnMouseDown(isOpen)}
              onClick={() => {
                if (stateOnMouseDown) {
                  // if popup was open on mouse down, explicitly set isOpen to false
                  // (toggle won't work because OnClickOutside is triggered first)
                  setOpen(false);
                  setStateOnMouseDown(false);
                } else {
                  setOpen(!isOpen);
                }
              }}
            >
              <FontAwesomeIcon icon={['fas', 'calendar-alt']} />
            </Button>
          </div>

          {(meta.error || meta.submitError) && meta.touched && (
          <span id={`${id}Error`} className="errorTxt invalid-feedback">{meta.error || meta.submitError}</span>
          )}

          {helpText && <p className="helpTxt">{helpText}</p>}
        </div>
      )}
    </ReactField>
  );
}

function DropdownField({
  id,
  name,
  label,
  placeholder,
  helpText,
  initialValue,
  autoComplete = undefined,
  className = '',
  values,
  optional = false,
  sensitive = true,
  labelWithMargin = false,
  disableFieldStateUI = false,
}) {
  return (
    <ReactField name={name} initialValue={initialValue}>
      {({ input, meta }) => (
        <div className={`${className} formField`}>
          <Label
            text={label}
            name={name}
            optional={optional}
            id={id}
            labelWithMargin={labelWithMargin}
          />

          <select
            {...input}
            id={id}
            name={name}
            className={`${sensitive ? 'inspectletIgnore' : ''} form-select ${((!disableFieldStateUI && meta.touched) || '') && (meta.error || meta.submitError ? 'is-invalid' : 'is-valid')}`}
            autoComplete={autoComplete}
          >
            <option value="">{placeholder}</option>
            {values.map(({ [getLanguage()]: text, Code }) => (
              <option value={Code} key={Code}>{text}</option>
            ))}
          </select>

          {(meta.error || meta.submitError) && meta.touched && (
          <span id={`${id}Error`} className="errorTxt invalid-feedback">{meta.error || meta.submitError}</span>
          )}

          {helpText && <p className="helpTxt">{helpText}</p>}
        </div>
      )}
    </ReactField>
  );
}
export const Dropdown = translate(DropdownField);

export function Paysafe({
  id,
  name,
  label,
  formatText,
  className = '',
  optional = false,
  fieldState: { touched, empty, error },
  cvvField = false,
}) {
  return (
    <div className={`formField ${className}`}>
      <Label
        text={label}
        name={name}
        optional={optional}
        formatText={formatText}
      />
      <div
        id={id}
        name={name}
        className={`inspectlet-sensitive paysafeField form-control ${cvvField ? 'cvvMargin' : ''} ${(touched || '') && (error ? 'is-invalid' : 'is-valid')}`}
      />
      {touched && error && (
        <span className="errorTxt invalid-feedback">{empty ? t('Validation_Required') : t(`Validation_Invalid_${name.charAt(0).toUpperCase() + name.slice(1)}`)}</span>
      )}
    </div>
  );
}

export function Search({
  inputOnClick,
  currentRefinement,
  onChange,
  buttonOnClick,
}) {
  return (
    <div className="searchBox input-group">
      <label
        htmlFor="searchInput"
        className={`floatingLabel ${currentRefinement === '' ? '' : 'active'}`}
      >
        {t('Landing_Field_Search_Placeholder')}
      </label>
      <input
        onClick={inputOnClick}
        className="searchInput"
        type="text"
        placeholder={t('Landing_Field_Search_Placeholder')}
        value={currentRefinement}
        onChange={onChange}
        id="searchInput"
      />
      <button
        type="button"
        title={t('IconDescription_AlgoliaSearch')}
        onClick={buttonOnClick}
        className="searchButton"
      >
        <FontAwesomeIcon
          icon={['fal', 'magnifying-glass']}
          className="searchIcon"
        />
        <span className="tooltiptext">{t('IconDescription_AlgoliaSearch')}</span>
      </button>
    </div>
  );
}

export function Quantity({
  inputOnClick,
  value,
  setValue,
  id,
  disabled,
  locked,
  compact,
  cart = false,
  location = false,
  productName,
  prodCode,
  denom,
}) {
  function setValueInputWrapper(currValue) {
    logAmpEvent(
      events.USER_UPDATED_PRODUCT_QUANTITY,
      { Product: prodCode, Denomination: denom, Quantity: currValue, At: location }
    );
    setValue(currValue);
  }

  function setValueButtonWrapper(currValue, add = false) {
    logAmpEvent(
      events.USER_CLICKED_QUANTITY_BUTTON,
      { Product: prodCode, Denomination: denom, Quantity: currValue, At: location, Type: add ? 'Add' : 'Remove' }
    );
    setValue(currValue);
  }

  function setQuantityText(text, quantity = null) {
    return replaceProductString(t(cart ?
      `${text}_Cart` :
      text), denom, productName, quantity);
  }

  return (
    <div className="quantityInputContainer">
      {!compact && <label className="quantityLbl upperCase" htmlFor={id}>{t('Products_Lbl_Quantity')}</label>}
      <div className="quantityButtonCont">
        {locked || (
        <button
          type="button"
          aria-label={setQuantityText('ButtonDescription_RemoveQuantity')}
          title={setQuantityText('ButtonDescription_RemoveQuantityTooltip')}
          onClick={() => {
            if (value > 1 || cart) {
              setValueButtonWrapper(value - 1);
              if (cart && value <= 1) {
                logAmpEvent(events.USER_REMOVED_ITEM_FROM_CART, {
                  Product: prodCode,
                  Denomination: denom,
                });
                setScreenReaderAlert(replaceProductString(t('ScreenReaderAlert_RemovedFromCart'), denom, productName));
              } else {
                setScreenReaderAlert(setQuantityText('ScreenReaderAlert_ChangedQuantity', value - 1));
              }
            }
          }}
          className="quantityButton"
        >
          <FontAwesomeIcon icon={['fas', 'minus']} />
        </button>
        )}
      </div>
      <input
        id={compact ? `${id}-empty` : id}
        onClick={() => {
          if (inputOnClick) inputOnClick();
          if (!locked && disabled) paysafeCartAlert();
        }}
        className="quantityInput"
        type="text"
        min="1"
        max={MAX_DENOM_QUANTITY}
        readOnly={disabled || locked}
        onChange={({ currentTarget: { value: v } }) => {
          const formattedValue = v.replace(/\D/g, '').replace(/^0+/, '');
          if (formattedValue === '') { setValue('', true); return; }
          if (formattedValue > MAX_DENOM_QUANTITY) {
            setValue(MAX_DENOM_QUANTITY, true);
            setScreenReaderAlert(t('Cart_GiftAlert_Lbl').replace('xMax', MAX_DENOM_QUANTITY));
            setScreenReaderAlert(setQuantityText('ScreenReaderAlert_ChangedQuantity', MAX_DENOM_QUANTITY));
          } else {
            setValue(parseInt(formattedValue, 10), true);
            setScreenReaderAlert(setQuantityText('ScreenReaderAlert_ChangedQuantity', formattedValue));
          }
        }}
        onBlur={() => setValueInputWrapper(value || 1)}
        value={value}
        aria-label={compact ? t('Products_Lbl_Quantity') : undefined}
      />
      <div className="quantityButtonCont">
        {locked || (
        <button
          type="button"
          onClick={() => {
            if (!disabled) {
              if (value >= MAX_DENOM_QUANTITY) {
                giftCartAlert();
                setScreenReaderAlert(t('Cart_GiftAlert_Lbl').replace('xMax', MAX_DENOM_QUANTITY));
              } else {
                setValueButtonWrapper(value + 1, true);
                setScreenReaderAlert(setQuantityText('ScreenReaderAlert_ChangedQuantity', value + 1));
              }
            } else {
              paysafeCartAlert();
            }
          }}
          aria-label={setQuantityText('ButtonDescription_AddQuantity')}
          title={setQuantityText('ButtonDescription_AddQuantityTooltip')}
          className="quantityButton"
        >
          <FontAwesomeIcon icon={['fas', 'plus']} />
        </button>
        )}
      </div>
    </div>
  );
}
